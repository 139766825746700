import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import HeaderBottom from "../components/HeaderBottom";

const ProductPageTemplate = ({ data, pageContext, location }) => {
  const product = data.markdownRemark.frontmatter;
  const siteData = data.home.frontmatter;
  const siteTitle = data.site.siteMetadata.title;

  // Generate WA URL
  let waUrl = `https://wa.me/${siteData.waNum.replace(/^0/g, "62")}`;
  let waMessage = siteData.waMessage.replace("<url>", `${location.href}`);
  waUrl = waUrl + `?text=${encodeURI(waMessage)}`;

  console.log(product);
  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "scroll-is--active",
        }}
      />
      <HeaderBottom />
      <main class="site-content site-content--product-single" id="wrapper">
        <div class="site-content__inner">
          <div class="site-content__holder">
            <article class="product product--single">
              <div class="product__thumbnail slick-slider">
                <div class="product__thumbnail-item slick-slide">
                  <img src={product.thumbnail} alt="" />
                </div>
                {product.images &&
                  product.images.map(({ image }, index) => {
                    return (
                      <div class="product__thumbnail-item slick-slide">
                        <img src={image} alt={`product image - ${index}`} />
                      </div>
                    );
                  })}
              </div>
              <ul class="post__sharing">
                <li class="post__sharing-item post__sharing-item--menu">
                  <a href="/products">
                    <i>&nbsp;</i>
                  </a>
                </li>
                <li class="post__sharing-item">
                  <a href="https://www.facebook.com/Apex-Warrior-104900274938867/"></a>
                </li>
                <li class="post__sharing-item">
                  <a href="https://instagram.com/apexwarrior.id"></a>
                </li>
              </ul>
              <div class="product__header">
                <ul class="product__cats list-unstyled">
                  <li class="product__cats-item color-warning">
                    <a href="#">{product.tag.split("-")[1]}</a>
                  </li>
                </ul>
                <h2 class="product__title h3">{product.name}</h2>
                <ul class="product__meta list-unstyled">
                  <li class="product__meta-item product__price">
                    {new Intl.NumberFormat("id", {
                      style: "currency",
                      currency: "IDR",
                    }).format(parseFloat(product.price))}
                  </li>
                  <li class="product__meta-item product__ratings">
                    <div class="fas star-rating">
                      <span style={{ width: "100%" }}>&nbsp;</span>
                      <span class="star-rating__label">
                        5.0 out of all reviews
                      </span>
                    </div>
                  </li>
                </ul>
                {product.inStock ? (
                  <span class="badge badge-pill badge-success ready-stock">
                    ready ✔
                  </span>
                ) : (
                  <span class="badge badge-pill badge-light ready-stock">
                    out of stock ✘
                  </span>
                )}
              </div>
              <div class="product__excerpt">{product.description}</div>
              <ul class="product__options list-unstyled">
                <li class="product__option">
                  <span class="product__option-label">Color</span>
                  <ul class="color-control">
                    {product.colors &&
                      product.colors.map((color) => (
                        <li>
                          <a style={{ backgroundColor: color.color }} />
                        </li>
                      ))}
                  </ul>
                </li>
                {/* <li class="product__option">
                  <span class="product__option-label">Size</span>
                  <div class="size-control">
                    <select name="select-size" id="select-size">
                      <option value="small">Small</option>
                      <option value="medium" selected="selected">
                        Medium
                      </option>
                      <option value="large">Large</option>
                    </select>
                  </div>
                </li>
                <li class="product__option">
                  <span class="product__option-label">Quantity</span>
                  <div class="quantity-control">
                    <button class="quantity-control__minus">&nbsp;</button>
                    <input type="number" step="1" value="2" size="4" />
                    <button class="quantity-control__plus">&nbsp;</button>
                  </div>
                </li> */}
              </ul>
              {/* <a class="product__button btn btn-lg" href="#">Add to cart +</a> */}

              <div class="short-desc section product-shop-links">
                <p>
                  <strong>Shop Now:</strong>
                </p>
                <div className="section online-shop-payment-methods row">
                  {product.tokpedUrl && (
                    <a target="__blank" href={product.tokpedUrl}>
                      <img
                        style={{
                          height: "25px",
                          transform: "translateY(-1px)",
                        }}
                        src="/azzet/img/payment/tokpedremovedwhitebg.png"
                      />{" "}
                      tokopedia
                    </a>
                  )}
                  {product.blibliUrl && (
                    <a target="__blank" href={product.blibliUrl}>
                      <img
                        style={{
                          height: "27px",
                          transform: "translateY(-2px)",
                        }}
                        src="/azzet/img/payment/blibliremovedwhitebg.png"
                      />{" "}
                      blibli
                    </a>
                  )}
                  {product.shopeeUrl && (
                    <a target="__blank" href={product.shopeeUrl}>
                      <img
                        style={{
                          height: "25px",
                          transform: "translateY(-3px)",
                        }}
                        src="/azzet/img/payment/shopeeremovedwhitebg.png"
                      />{" "}
                      shopee
                    </a>
                  )}
                  {product.lazadaUrl && (
                    <a target="__blank" href={product.lazadaUrl}>
                      <img
                        style={{
                          height: "25px",
                          transform: "translateY(-3px)",
                        }}
                        src="/azzet/img/payment/lazadaremovedwhitebg.png"
                      />{" "}
                      lazada
                    </a>
                  )}
                  {product.jdidUrl && (
                    <a
                      target="__blank"
                      href={product.jdidUrl}
                      style={{
                        transform: "translateY(-3px)",
                      }}
                    >
                      <img
                        style={{
                          height: "35px",
                          transform: "translateY(-1px)",
                        }}
                        src="/azzet/img/payment/jdidremovedwhitebg.png"
                      />{" "}
                      jd.id
                    </a>
                  )}
                </div>
              </div>
              <a
                href={waUrl}
                target="__blank"
                class="product__button btn btn-lg"
              >
                <i className="fab fa-whatsapp product-wa"></i>Ask us
              </a>
            </article>

            {/* <div class="product-review-form">
              <h4 class="product-review-form__title">Write a review</h4>
              <form action="#" class="review-form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" class="form-control" id="input-default" placeholder="Your Name"/>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group select-wrapper">
                      <select name="select-rating" id="select-rating">
                        <option value="5">5 Stars</option>
                        <option value="4">4 Stars</option>
                        <option value="3">3 Stars</option>
                        <option value="2">2 Stars</option>
                        <option value="1">1 Stars</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea name="input-review" cols="30" rows="5" class="form-control" id="input-review" placeholder="Your Review"></textarea>
                    </div>
                  </div>
                </div>
                <button class="btn btn-secondary">Post Review</button>
              </form>
            </div> */}
            <h4 class="mb-5">Description</h4>
            <div
              class="product__excerpt product__description"
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />

            {product.reviews && (
              <div class="product-reviews">
                <h4 class="product-reviews__title">{`Reviews ( ${product.reviews.length} )`}</h4>
                <ol class="reviews">
                  {product.reviews.map((review, index) => {
                    return (
                      <li class="review">
                        <div class="review__header">
                          <div class="review__title">{review.summary}</div>
                          <div class="fas star-rating">
                            <span style={{ width: "100%" }}>&nbsp;</span>
                          </div>
                        </div>
                        <div class="review__body">{review.review}</div>
                        <ul class="review__meta">
                          <li class="review__author">{`By ${review.reviewer}`}</li>
                          <li class="review__date">{review.date}</li>
                        </ul>
                      </li>
                    );
                  })}
                </ol>
              </div>
            )}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ProductPageTemplate;

export const pageQuery = graphql`
  query ProductBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    home: markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        waNum
        waMessage
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        name
        date(formatString: "MMMM DD, YYYY")
        description
        price
        inStock
        thumbnail
        images {
          image
        }
        colors {
          color
        }
        tag
        redLabel
        blackLabel
        tokpedUrl
        blibliUrl
        shopeeUrl
        lazadaUrl
        jdidUrl
        instaUrl
        reviews {
          review
          summary
          reviewer
          date(formatString: "MMM DD")
        }
      }
    }
  }
`;
